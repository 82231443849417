<template>
  <div class="select-device-form" @keyup.enter="openList()">
    <a id="select-button" class="select-button" @click.prevent="openList">{{ form_title }}</a>
    <div v-if="opened" id="select-device-form-list" class="select-device-form-list" @scroll="onScroll">
      <input v-model="search" type="text" class="form-control" @click.stop />
      <ul id="lists">
        <li
          v-for="(item, i) in filteredLists"
          id="list-item"
          :key="`select-device-form-${i}`"
          tabindex="0"
          @keyup.enter.stop="chooseItem(item)"
          @click.prevent="chooseItem(item)"
        >
          {{ field ? item[field] : item }}
        </li>
        <li v-if="loading">
          <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectForm',
  props: {
    title: {
      type: String,
      required: true,
      default: 'Select List',
    },
    lists: {
      type: Array,
      required: true,
      default: () => [],
    },
    field: {
      type: String,
      required: true,
      default: '',
    },
    selected: {
      default: -1,
    },
    type: {
      type: String,
      required: true,
      default: 'store',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opened: false,
      search: '',
      filteredLists: this.lists,
    };
  },
  computed: {
    form_title() {
      if (this.isScreen && this.selected && 'value' in this.selected) {
        return this.selected.value;
      }

      if ((this.selected || this.selected === 0) && this.selected > -1) {
        return this.field ? this.lists[this.selected][this.field] : this.vModel[this.selected];
      }
      return this.title;
    },
  },
  watch: {
    search() {
      this.filteredLists = this.lists.filter(
        (el) => (el.name || el.key || '').toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    },
    lists(newVal, oldVal) {
      if (newVal.length != oldVal.length) {
        this.filteredLists = newVal.map((item, i) => {
          item.position = i;
          return item;
        });
      }
    },
  },
  created() {
    document.addEventListener('click', (evt) => {
      if (this.opened) {
        const button = document.getElementById('select-button');
        const item = document.getElementById('list-item');
        const target = evt.target;
        if (item && item.id == target.id) {
          return;
        }
        if (button && button.id == target.id) {
          return;
        }
        this.opened = false;
      }
    });
    this.setFilteredList();
  },
  methods: {
    openList() {
      this.opened = !this.opened;
    },
    chooseItem(item) {
      const { position } = item;
      this.opened = false;

      if (this.isScreen) {
        this.$emit('change', { target: { value: position, id: item.id } });
        return;
      }

      this.$emit('change', { target: { value: position } });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        this.$emit('load-more', this.type);
      }
    },
    setFilteredList() {
      this.filteredLists = this.lists.map((item, i) => {
        item.position = i;
        return item;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/sass/_mixin-revamp.scss';

.select-device-form {
  position: relative;
  .select-button {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    cursor: pointer;
    background-color: #fff;
    width: 100%;
    border-radius: 7px;
    height: 90px;
    font: normal 32px/39px $lato-regular;
    color: $greylight;
    padding: 25px 88px 25px 30px;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.08);
    position: relative;
    &:after {
      content: '';
      width: 24px;
      height: 12px;
      background: transparent url('~static/img/device-settings/icon-drop-down.svg') no-repeat 0 0;
      background-size: 24px 12px;
      position: absolute;
      top: 40px;
      right: 32px;
    }
  }
  .select-device-form-list {
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    max-height: 500px;
    overflow: auto;
    padding: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.08);
    input {
      height: 80px;
      font: normal 32px/39px $lato-regular;
    }
    ul {
      margin: 0;
      li {
        font: normal 32px/39px 'Lato-Regular', sans-serif;
        padding: 25px 30px;
        border-bottom: 1px solid #ddd;
        &:hover {
          background-color: #f5f5f5;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1079px) {
  .select-device-form {
    .select-button {
      height: 72px;
      font: normal 20px/24px 'Lato-Regular', sans-serif;
    }
    .select-device-form-list ul li {
      font: normal 20px/24px 'Lato-Regular', sans-serif;
    }
  }
}
</style>
